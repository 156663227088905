/* Estilos principales para darle forma de boleto aéreo */
.contact-form-ticket {
  background-color: #f7f7f7;
  border-radius: 20px;
  max-width: 600px;
  /* margin: 30px auto; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 2px solid #312783;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.ticket-header {
  background-color: #001c5b;
  color: white;
  padding: 20px;
  text-align: center;
}

.ticket-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.ticket-body {
  padding: 20px;
  position: relative;
}

.form-group label {
  font-weight: bold;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #312783;
  outline: none;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
  display: block;
}


button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #312783;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button[type="submit"]:hover:not(:disabled) {
  background-color: #1e1e6a;
}

/* Borde superior para darle forma de ticket aéreo */
.contact-form-ticket::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 50% 50% 0 0;
  border-top: 2px dashed #ccc;
}

.contact-form-ticket::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 0 0 50% 50%;
  border-bottom: 2px dashed #ccc;
}

/* ReCAPTCHA centrado */
.form-group .g-recaptcha {
  margin: 20px 0;
}

/* Estilo de líneas punteadas */
.ticket-body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background: linear-gradient(transparent 5px, #ccc 5px, #ccc 10px, transparent 10px);
}

/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .contact-form-ticket {
    padding: 15px;
    max-width: 90%; /* Reduce el ancho en pantallas pequeñas */
  }

  .form-group label {
    font-size: 0.9rem; /* Reduce ligeramente el tamaño del label */
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9rem; /* Reduce ligeramente el tamaño del texto en inputs */
  }

  button {
    font-size: 0.9rem; /* Ajusta el tamaño del botón */
  }
}

@media (max-width: 480px) {
  .contact-form-ticket {
    padding: 0px;
    max-width: 100%; /* Usa el 100% del ancho en móviles */
  }

  .form-group input,
  .form-group textarea {
    padding: 8px; /* Reduce el padding para móviles */
  }

  button {
    font-size: 0.85rem; /* Ajusta aún más el tamaño del botón */
  }
}