.c-features__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #2e3870;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin: 10px;
  min-height: 300px; /* Establece una altura mínima para las tarjetas */
  box-sizing: border-box;
  color: #ffffff
}
  
  .o-box {
    box-sizing: border-box;
  }
  
  .u-flex--11 {
    flex: 1 1 auto;
  }
  
  .u-border--full {
    border-width: 1px;
    border-style: solid;
  }
  
  .bdr-clr--lighter-gray {
    border-color: #d3d3d3;
  }
  
  .u-border-radius {
    border-radius: 8px;
  }
  
  .feature-icon {
    font-size: 5rem;
    margin-bottom: 15px;
  }
  
  .feature-content {
    text-align: center;
    flex-grow: 1; /* Permite que el contenido crezca para llenar el espacio disponible */
  }
  
  .feature-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .feature-description {
    font-size: 1.5rem;
    color: #ffffff;
  }