.rounded-right {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.custom-alert .btn-close {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: 1.2rem;
  opacity: 0.8;
}
.mt-5 {
  margin-top: 0 !important;
}
.checkbox-label {
  margin-right: 10px; /* Ajusta este valor según sea necesario */
}

.custom-alert .btn-close:hover {
  opacity: 1;
}

@media (max-width: 700px) {
  .flex-column-700 {
    flex-direction: column !important;
  }
}