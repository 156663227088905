.App {
  text-align: center;
  overflow: hidden;
}

.App-header {
  position: relative;
  min-height: 100vh;
  background-color: #fcfcfc; /* Color de fondo actualizado */
  color: white;
}

.banner {
  position: relative;
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Alinea el logo y el texto en columna */
  overflow: hidden;
}
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.logo {
  max-width: 400px;
  max-height: 400px;
  width: 100%;
  height: auto;
  z-index: 1;
  margin-bottom: 20px;
}

.div1, .div2 {
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 10px;
}

.reservation-bar {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.slick-prev::before, .slick-next::before {
  color: #021a58 !important;
}

@keyframes spinAndStop {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.colorText {
  color: #000411;
}

.color-text-all-title {
  color: #656d76;
}

.logo-text {
  font-size: 3.5rem;
  color: white;
  margin-top: 10px;
  position: relative;
  z-index: 1;
  font-family: 'Courier New', Courier, monospace; /* Fuente monoespaciada tipo boleto aéreo */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto extra para el efecto */
  border-right: 4px solid white; /* Simula el cursor de la máquina de escribir */
  width: 0; /* Inicia con un ancho de 0 para la animación */
  animation: typing 10s steps(22) forwards, blink-caret 1.75s step-end infinite; /* Animaciones combinadas */
}

/* Animación de máquina de escribir */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Animación del cursor parpadeante */
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}

.social-icons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  font-size: 1.5rem; /* Incremento del tamaño de los iconos en un 50% */
}

.social-icons a {
  color: white;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ffcc00;
}



.content {
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
}



.slick-slide img {
  display: inline !important;
}

.instagram-feed-content, .instagram-feed-right, .youtube-feed {
  padding: 20px;
  background-color: white;
  color: black;
  margin: 20px; /* Incremento del margen a 20px */
}

.instagram-feed h2, .instagram-feed-right h2, .youtube-feed h2 {
  font-size: 2rem;
}

.instagram-feed img, .instagram-feed-right img {
  object-fit: cover;
  margin: 5px;
}

.social-feeds {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.youtube-feed, .instagram-feed-right {
  flex: 1;
  margin: 20px; /* Incremento del margen a 20px */
}

/* Estilos para hacer el iframe de YouTube responsivo */
.youtube-feed iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.youtube-feed-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Aspect Ratio 16:9 */
  margin-bottom: 20px;
}

.youtube-feed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Estilos para el contenedor de Google Maps y el formulario */
.map-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.map-container, .form-container {
  flex: 1;
  margin: 10px; /* Espaciado interno del contenedor */
}

.map-container iframe {
  width: 100%;
  height: 400px; /* Altura fija para el mapa */
  border: 0;
}

.form-container {
  padding: 10px;
  border-radius: 8px;
}

.form-container h2 {
  margin-top: 0;
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 39px 50px 0 30px;
}

.form-container input, .form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #312783;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.form-container button:hover {
  background-color: #1e1e6a;
}

/* Estilos para el slider de servicios */
.services-slider {
  padding: 20px;
  /* background-color: #000d42; */
}

.services-slider h2 {
  font-size: 2rem;
}

.service-slide {
  padding: 20px;
  text-align: center;
}
.text-justify {
  font-size: 16px;
}
.service-slide img {
  width: 100%;
  height: auto;
  max-width: 600px; /* Ajusta según el tamaño deseado */
  border-radius: 8px;
}

/* Estilos para el footer */
.App-footer {
  background-color: #312783;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer-content p {
  font-size: 16px; /* Tamaño de fuente para el pie de página */
  margin: 0;
}

.footer-logo {
  max-width: 220px; /* Tamaño del logo en el pie de página */
  margin-left: 20%;
}

/* Estilos para el botón flotante de WhatsApp */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366; /* Color de fondo verde característico de WhatsApp */
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Asegura que el botón esté encima de otros elementos */
}

.whatsapp-float a {
  color: white;
  font-size: 2.5rem; /* Tamaño del ícono */
  text-decoration: none;
}

.whatsapp-float a:hover {
  color: #128C7E; /* Color de icono en hover */
}

/* Responsivo */
@media (max-width: 768px) {
  .logo {
    width: 300px; 
   }

  .map-container, .form-container {
    margin: 10px 0;
  }

  .map-container iframe {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .logo {
   width: 200px; 
   margin-top: 40px;
   margin-bottom: 5px;
   display: block;
  }

  .form-container {
    max-width: 390px;
  }

  .map-container iframe {
    height: 200px;
  }

  .form-container button {
    font-size: 0.9rem;
  }
}

@media (max-width: 390px) {
  .form-container {
    max-width: 335px;
  }
}
