.image-gallery {
  /* display: flex; */
  /* overflow-x: scroll; */
}

.image-container {
  position: relative;
  margin-right: 10px;
}

.gallery-image {
  display: block;
  width: 95%;
  height: auto;
}

.view-map-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
}

.button-maps-link {
  margin-top: 70%;
}

.image-container:hover .view-map-button {
  opacity: 1;
}

.modal-new {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: absolute;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}