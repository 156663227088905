.footer {
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: 400px;
}

.footer-section a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
}

.footer-middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logos {
  display: flex;
  gap: 10px;
  margin: 10px;
  flex-flow: wrap;
}

.footer-logo-back {
  height: 51px;
  max-width: 150px;
}

.footer-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  font-size: 19px;
  gap: 20px;
  flex-flow: wrap;
}

.footer-social a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
}

.footer-social i {
  margin-right: 5px;
}

.footer-main-logo {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.main-logo {
  height: 67px;
}

.footer-bottom {
  border-top: 1px solid #555;
  padding-top: 10px;
}

@media (max-width: 1400px) {
  .footer-social {
    display: grid;
    font-size: 28px;
  }
  
}

@media (max-width: 480px) {
  .footer-main-logo {
    max-width: 268px;
  }
  
}