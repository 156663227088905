:root {
  --bs-heading-color: #2e3870
} 

.instagram-feed {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 20px 0;
    width: 100%;
  }

  .text-content-init {
    font-size: 55px;
  }

  .color-text-init {
    color: #2e3870;
  }

  .instagram-feed-container {
    width: 100%;
  }
  
  .media-container {
    flex: 1 1 calc(100% / 6); /* Distribuye 6 elementos uniformemente */
    max-width: 96%;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .media-container img, .media-container video {
    width: 100%;
    height: 500px;
    border-radius: 8px;
  }
  
  .modal-insta {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-new img, .modal-content-new video {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
  }
  
  .modal-content-new {
    position: relative;
    max-width: 430px;
  }

  .o-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  
  @media (max-width: 768px) {
    .media-container {
      max-width: 48%; /* 2 columnas en pantallas más pequeñas */
    }
    .text-content-init {
      font-size: 25px;
    }
  
  }
  
  @media (max-width: 480px) {
    .media-container {
      max-width: 100%; /* Una columna en pantallas muy pequeñas */
    }
    .instagram-feed {
        flex-flow: column;
    }
    .text-content-init {
      font-size: 29px;
    }
  
  }
  