.modal-new-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
  }
  
  .modal-new {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    display: block;
    scroll-behavior: smooth;
    margin-bottom: 107px;
    max-block-size: 600px;
      overflow-y: auto; /* Permite el desplazamiento vertical */
  }
  
  .modal-new-header {
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute; /* Posiciona el botón de cierre */
    top: 10px; /* Ajusta según sea necesario */
    right: 10px; /* Ajusta según sea necesario */
  }
  
  .modal-new-content {
    margin-top: 20px;
  }
  
  .modal-new-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }