.c-section-home {
  padding: 40px 0;
}

.c-section-home--features {
  /* background-color: #f9f9f9; */
}

.o-section--top-small {
  padding-top: 20px;
}

.o-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 15px;
}

.o-row {
  /* display: flex; */
  flex-wrap: wrap;
  margin: 0 -15px;
}

.o-col {
  padding: 0 15px;
}

.o-col--12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.o-col--4 {
  flex: 0 0 33.3333%;
  /* max-width: 33.3333%; */
 /* max-height: 270px;*/
}

.c-section-home__title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots {
  bottom: -30px;
}

.slick-prev, .slick-next {
  z-index: 1;
}