.instagram-profile {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  background-color: #fff;
}

.header {
  display: flex;
  align-items: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-info {
  flex-grow: 1;
  text-align: initial;
  margin-left: 10px;
}

.username {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.bio {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.stat {
  text-align: center;
}

.stat strong {
  display: block;
  font-size: 18px;
  font-weight: bold;
}

.photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.photo {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

@media (max-width: 500px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  .avatar {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .username {
    font-size: 20px;
  }

  .bio {
    text-align: center;
    font-size: 14px;
  }

  .stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 10px;
  }

  .photos {
    grid-template-columns: repeat(2, 1fr);
  }
}
