.reservation-container {
  margin: auto;
  background-color: rgba(0, 0, 255, 0.1); /* Fondo para todo el componente */
  padding: 20px;
  border-radius: 10px;
 /* z-index: 10; /* Añadido z-index */
  position: relative; /* Necesario para que z-index funcione */
}

.tabs {
  display: flex;
  /* justify-content: space-around; */
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  overflow-x: auto; /* Permite el deslizamiento horizontal */
  white-space: nowrap; /* Evita que los botones se envuelvan */
}

.tabs button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-flex; /* Cambiado a inline-flex para el deslizamiento */
  align-items: center;
  gap: 10px;
  font-size: 16px;
  flex-shrink: 0; /* Evita que los botones se encojan */
}

.tabs button i {
  font-size: 30px;
}

.tabs button.active {
  background-color: #007bff;
  color: white;
  border-bottom: 2px solid white;
}

.tabs button:hover {
  background-color: #0056b3;
  color: white;
}

.reservation-form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reservation-form button:hover {
  background-color: #0056b3;
}

@media (max-width: 1100px) {
  .reservation-container {
    max-width: 950px;
  }
}

@media (max-width: 815px) {
  .reservation-container {
    max-width: 650px;
  }
}

/* Estilos adicionales para dispositivos móviles */
@media (max-width: 600px) {
  .tabs {
    justify-content: flex-start; /* Alinea los tabs al inicio */
  }

  .tabs button {
    flex: 0 0 auto; /* Evita que los botones se estiren */
  }
  .reservation-container {
    max-width: 500px;
  }
}

@media (max-width: 480px) {
  .reservation-container {
    max-width: 375px;
  }
}
